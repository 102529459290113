import { SafeResourceUrl } from "@angular/platform-browser";

export class Token {
    id: string = "";
    name: string = "";
    tokenUri: string = "";
    poster: string = "";
    url: SafeResourceUrl = "";
    traits: any = [];
    tokenRaw: any = {};
    projectType: string = "js";
    projectId: string = "";
    owner: string = "";

    constructor() {
    }


  }