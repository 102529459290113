import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Web3Provider } from './web3-provider'
import { GlobalsProvider } from './globals-provider'
import * as TokenArtifacts from '../_contracts/abi-token.json'
import { Project } from '../_interfaces/project';
import { Token } from '../_interfaces/token';
import { DomSanitizer } from '@angular/platform-browser';
import { Utils } from './utils';


@Injectable({
    providedIn: 'root'
})
export class ProjectProvider {

    constructor(
        public http: HttpClient,
        public web3Provider: Web3Provider,
        public globals: GlobalsProvider,
        public sanitizer: DomSanitizer,
        public utils: Utils) { }


    private tokenContract;
    private purchaseContract;

    public loaded: boolean = false;

    public isOwnerObservable = new Subject<number>();
    public tokenList = new Subject<any>();

    /*
    /* Smart Contract Methods
    */

    async load(): Promise<void> {
        return new Promise(async (resolve, reject) => {

            //Load the token contract
            var tokenArtifacts = (TokenArtifacts as any).default;
            this.tokenContract = new this.web3Provider.web3.eth.Contract(tokenArtifacts, this.globals.tokenContractAddress());

            resolve();

        });

    }

    checkIfOwner(address) {
        this.tokenContract.methods.balanceOf(address).call().then(result => {
            console.log(result);
            var balance = parseInt(result);
            this.isOwnerObservable.next(balance);
        });
    }

    //gets the on chain project info
    getProjectDetails(projectId, refresh = false): Promise<Project> {
        return new Promise(async (resolve, reject) => {

            if (!this.loaded)
                await this.load();

            var projDPromise = this.tokenContract.methods.projectDetails(projectId).call();
            var projscriptUrlPromise = this.tokenContract.methods.projectScriptByIndex(projectId, 0).call();
            var projBaseUriPromise = this.tokenContract.methods.projectURIInfo(projectId).call();
            var projTokenInfoPromise = this.tokenContract.methods.projectTokenInfo(projectId).call();
            var projScriptInfoPromise = this.tokenContract.methods.projectScriptInfo(projectId).call();

            Promise.all(
                [
                    projDPromise,
                    projscriptUrlPromise,
                    projBaseUriPromise,
                    projTokenInfoPromise,
                    projScriptInfoPromise

                ]).then(result => {

                    var p = new Project();
                    p.id = projectId;
                    p.name = result[0][0];
                    p.artist = result[0][1];
                    p.desc = result[0][2];
                    p.artistWebsite = result[0][3];
                    p.license = result[0][4];
                    p.projectScriptUrl = result[1];
                    p.projectBaseUri = result[2].projectBaseURI;
                    p.projectBaseIpfsUri = result[2].projectBaseIpfsURI;
                    p.mintPrice = parseInt(result[3].pricePerTokenInWei);
                    p.invocations = parseInt(result[3].invocations);
                    p.maxInvocations = parseInt(result[3].maxInvocations);
                    p.purchaseContract = result[3].purchaseContract;
                    p.acceptsMintPass = result[3].acceptsMintPass;
                    p.mintPassProjectId = result[3].mintPassProjectId;
                    p.active = result[3].active;
                    p.paused = result[4].paused;
                    p.locked = result[4].locked;
                    p.populated = "full";

                    if(result[3].tokensBurned)
                        p.tokensBurned = result[3].tokensBurned;

                    if (p.projectBaseUri.lastIndexOf("/") != p.projectBaseUri.length - 1)
                        p.projectBaseUri = p.projectBaseUri + "/";

                    this.globals.project = p;

                    resolve(p);
                });

        })
    }

    //gets the on chain project info
    getProjectDetailsBasic(projectId = null) {
        return new Promise(async (resolve, reject) => {

            if (!this.loaded)
                await this.load();

            var projectIds = [];

            if (projectId)
                projectIds.push(projectId);
            else
                projectIds = await this.getAllProjectIds();

            for (var i = 0; i < projectIds.length; i++) {
                var id = projectIds[i];

                var projectDetailsPromise = this.tokenContract.methods.projectDetails(id).call();
                var projBaseUriPromise = this.tokenContract.methods.projectURIInfo(id).call();
                var projTokenInfoPromise = this.tokenContract.methods.projectTokenInfo(id).call();

                await Promise.all([projectDetailsPromise, projBaseUriPromise, projTokenInfoPromise]).then(values => {

                    var projectDetails = values[0];
                    var baseUris = values[1];
                    var projectTokenInfo = values[2];

                    var p = new Project();
                    p.id = id;
                    p.name = projectDetails[0];
                    p.artist = projectDetails[1];
                    p.desc = projectDetails[2];
                    p.artistWebsite = projectDetails[3];
                    p.license = projectDetails[4];                    
                    p.projectBaseUri  = baseUris.projectBaseURI;
                    p.projectBaseIpfsUri = baseUris.projectBaseIpfsURI;
                    p.active = projectTokenInfo.active;
                    p.populated = "partial";

                    var alreadyExists = false;

                    if (p.projectBaseUri.lastIndexOf("/") != p.projectBaseUri.length - 1)
                        p.projectBaseUri = p.projectBaseUri + "/";

                    this.globals.projects.forEach(proj => {
                        if(proj.id == p.id)
                            alreadyExists = true;
                    })
                    
                    if(!alreadyExists)
                        this.globals.projects.push(p);
                })
            }

            this.globals.projectsLoaded = true;
            resolve(this.globals.projects);
        })
    }

    async getAllProjectTokens(projectId) {
        return new Promise(async (resolve, reject) => {
            try {

                if (!this.loaded)
                    await this.load();

                var tokenIds = await this.tokenContract.methods.projectShowAllTokens(projectId).call();
                if ((tokenIds as Array<number>).length > 0)
                    resolve(tokenIds);
                else
                    resolve(false);

            }
            catch (ex) {
                console.log(ex);
                resolve(false);
            }
        })
    }

    async getRandomProjectTokenId(projectId): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {

                if (!this.loaded)
                    await this.load();

                var tokenIds = await this.getAllProjectTokens(projectId);
                if (tokenIds) {
                    var randomIndex = this.utils.randomIntBetween(0, (tokenIds as Array<number>).length - 1);
                    var output = { tokenId: tokenIds[randomIndex], tokenIdIndex: randomIndex, allTokenIds: tokenIds }
                    resolve(output);
                }
                else
                    resolve(false);

            }
            catch (ex) {
                console.log(ex);
                resolve(false);
            }
        })
    }

    async getRandomProjectToken(project, projectId) {
        return new Promise(async (resolve, reject) => {
            try {
                var token = new Token();
                var attemptedTokens = [];
                var randomToken = { projectId: "" } as any;

                //Keep iterating until we have a valid projectId aka valid token
                while (!randomToken.projectId && !token.projectId) {

                    //if its the first iteration
                    //get random token
                    if (attemptedTokens.length == 0)
                        randomToken = await this.getRandomProjectTokenId(projectId);
                    else {

                        //if not first iteration
                        // and we are at the end of the array
                        // reset index to 0
                        if (randomToken.tokenIdIndex == randomToken.allTokenIds.length - 1)
                            randomToken.tokenIdIndex = 0;
                        else //else increase index by 1
                            randomToken.tokenIdIndex++;

                        // get new token Id
                        randomToken.tokenId = randomToken.allTokenIds[randomToken.tokenIdIndex];
                    }

                    //if valid token id
                    //attempt get valid token
                    if (randomToken.tokenId) {
                        var token = await this.getToken(project, randomToken.tokenId);

                        //if project id exists, token is valid
                        if (token.projectId) {
                            resolve(token);
                        }
                        else {
                            //else add to attempted token list
                            //and continue
                            attemptedTokens.push(randomToken.tokenId);
                        }
                    }
                    else {
                        resolve(false)
                    }
                }
            }
            catch (ex) {
                console.log(ex);
                resolve(false);
            }
        })
    }

    async getTokensOfOwner(address) {
        return new Promise(async (resolve, reject) => {
            try {
                if (!this.loaded)
                    await this.load();

                this.globals.tokensLoaded = false;

                //empty collection first
                var tokenIds = await this.tokenContract.methods.tokensOfOwner(address).call();

                tokenIds = [...tokenIds];

                var noImages = 0;
                var localTokens = [];
                for (var i = 0; i < tokenIds.length; i++) {
                    var tokenId = tokenIds[i]

                    var project = this.getProjectFromTokenId(tokenId);
                    if (!project) {
                        var projectId = this.utils.reverseString(this.utils.reverseString(tokenId).substring(6))
                        var projects = await this.getProjectDetailsBasic(projectId);
                        project = projects[0];
                    }

                    var result = await this.getTokenData(project, tokenId);
                    if (result.image) {

                        //make sure token isnt being added again
                        var token = this.convertJsonToToken(result, tokenId);
                        localTokens.push(token);
                        this.tokenList.next(token);
                    }
                    else noImages++;

                }
                this.globals.userTokens = localTokens;
                if (this.globals.userTokens.length == (tokenIds.length - noImages)) {
                    this.globals.userTokens.sort((a, b) => {
                        return parseInt(b.tokenRaw.tokenID) - parseInt(a.tokenRaw.tokenID)
                    });
                }
                this.globals.tokensLoaded = true;
                resolve(true);
            }

            catch (ex) {
                console.log(ex);
                resolve(false);
            }
        });
    }

    getProjectFromTokenId(tokenId) {
        for (var i = 0; i < this.globals.projects.length; i++) {
            var proj = this.globals.projects[i];
            var projectIdLength = proj.id.toString().length;
            var firstTwo = tokenId.substring(0, projectIdLength);
            if (firstTwo == proj.id)
                return proj;
        }
    }

    getTokenData(project, tokenId) {

        let headers = new HttpHeaders();
        headers = headers.set("Content-Type", "application/json; charset=utf-8");

        var url;
        if (project.projectBaseUri)
            url = project.projectBaseUri + tokenId;
        else if (project.projectBaseIpfsUri)
            url = project.projectBaseIpfsUri;

        return this.http
            .get<any>(url).toPromise();

    }

    getToken(project, tokenId): Promise<Token> {
        return new Promise(async (resolve, reject) => {
            var result = await this.getTokenData(project, tokenId);
            var token = this.convertJsonToToken(result, tokenId);
            resolve(token);
        });
    }

    convertJsonToToken(json, tokenId) {

        var token = new Token();
        token.id = tokenId;
        token.projectId = json.projectId;
        token.name = json.name;
        token.url = this.sanitizer.bypassSecurityTrustResourceUrl(json.animation_url);
        token.poster = json.image;
        token.traits = json.traits;
        token.projectType = json.projectType;
        token.tokenRaw = json;

        return token;
    }

    async getAllProjectIds(): Promise<any> {
        return new Promise(async (resolve, reject) => {

            if (!this.loaded)
                await this.load();

            var nextProjectId = await this.tokenContract.methods.nextProjectId().call();
            var firstProjectId = 1;
            var result = [];

            var numOfProjects = nextProjectId - firstProjectId;

            for (var i = 0; i < numOfProjects; i++) {
                result.push(firstProjectId + i);
            }

            resolve(result);
        });
    }

    processData(data: any) {
        return data;
    }

    handleHttpError = (error: HttpErrorResponse) => {
        let errorMessage = 'Unknown error!';
        if (error.error.type == "error" && error.error.err) {
            // Custom errors
            if (error.error.err.message)
                errorMessage = `Error: ${error.error.err.message}`;
            else
                errorMessage = `Error: ${error.error.err}`;
        }
        else if (error.error instanceof ErrorEvent) {
            // Client-side errors
            errorMessage = `Error: ${error.error.message}`;
        }
        else {
            // Server-side errors
            errorMessage = error.message;
        }
        return of(errorMessage);
    }



}