import { Injectable } from '@angular/core';
import { Project } from '../_interfaces/project';
import { Token } from '../_interfaces/token';
import { Utils } from './utils';

@Injectable({
    providedIn: 'root'
})
export class GlobalsProvider {

    constructor(public utils: Utils) {}

    private testnetOpenseaUrl = "https://testnets.opensea.io/assets";
    private mainnetOpenseaUrl = "https://opensea.io/assets";

    private mainnetAppBaseUrl = "https://app.ntent.art/n/";
    private testnetAppBaseUrl = "https://test.app.ntent.art/n/";

    /*
    /* Third party endpoints
    */

    public webSocketConnectionId = ""

    public teamWallet = "0x5772A94eE0304325626371c8701a0dA825bfdF04";

    public infuraProjectId = "7177842209804a36ac212fc4fb3850aa";

    private rinkebyTokenContract = "0x76145916dd33D1B7f8Fd7d188d54599E36354f40";
    private ropstenTokenContract = "0x0da591D82b7b68389B4C28332a260Dc272657FCE"
    private kovanTokenContract = "0xbCC3ffDc2709127bB725D778C90A383299c19Ae3";    
    private mainnetTokenContract = "0x8Ec319e40E0A91A11cF584053EB5e420C613f01d"
    private testNetwork = "kovan";
    private devNetwork = "ropsten";

    private devAlchemyEndpointUrl = `https://eth-${this.devNetwork}.alchemyapi.io/v2/1PNbHKbJAKb2272av47kSBcluYgMER4M`
    private testAlchemyEndpointUrl = `https://eth-${this.testNetwork}.alchemyapi.io/v2/1PNbHKbJAKb2272av47kSBcluYgMER4M`
    private mainnetAlchemyEndpointUrl = "https://eth-mainnet.alchemyapi.io/v2/DRZjwTHapoNSD6-bg20-zSiMrrbq5WlG";

    /*
    /* TX verifications endpoints
    */
    public devTxBaseUrl = `https://${this.devNetwork}.etherscan.io/tx/`;
    public testTxBaseUrl = `https://${this.devNetwork}.etherscan.io/tx/`;
    public mainnetTxBaseUrl = "https://etherscan.io/tx/";
    
    /*
    /* Smart Contract Properties
    */
    private mainnetTokenContractAddress:string = this.mainnetTokenContract;
    private testTokenContractAddress:string = this.kovanTokenContract;
    private devTokenContractAddress:string = this.ropstenTokenContract;

    private production: boolean = true;
    private dev: boolean = false;

    public project: Project = new Project();
    public projects : Project[] = [];
    public userTokens : Token[] = [];
    public tokenBaseUri = "https://hosted.ntent.art/token-metadata/"
    public noWallet = false;
    public projectsLoaded = false;
    public tokensLoaded = false;
    public isMintPage = true;
    public justMinted = false;
    public isMobile = false;

    tokenContractAddress(){
         if(this.dev)
            return this.devTokenContractAddress;
        else if(this.production)
            return this.mainnetTokenContractAddress;
        else
            return this.testTokenContractAddress;
    }

    thirdpartyEndpoint(){
        if(this.dev)
            return this.devAlchemyEndpointUrl;
        else if(this.production)
            return this.mainnetAlchemyEndpointUrl;
        else 
            return this.testAlchemyEndpointUrl;
    }

    txVerificationEndpoint(){
        if(this.dev)
            return this.devTxBaseUrl;
        else if(this.production)
            return this.mainnetTxBaseUrl;
        else 
            return this.testTxBaseUrl;
    }

    openseaBaseUrl(){
        if(this.production && !this.dev)
        return this.mainnetOpenseaUrl;
    else 
        return this.testnetOpenseaUrl;
    }

    appBaseUrl(){
        if(this.production)
            return this.mainnetAppBaseUrl;
        else
            return this.testnetAppBaseUrl;
    }

    viewProjectOnOpenseaUrl(projectName){
        return `https://opensea.io/collection/ntent-art?search[query]=${encodeURIComponent(projectName)}&search[sortAscending]=false&search[sortBy]=VIEWER_COUNT`;
    }

    connectionId(){
        if(!this.webSocketConnectionId)
            this.webSocketConnectionId = this.utils.guid();
        
        return this.webSocketConnectionId;
    }


}
